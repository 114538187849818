import React from 'react';
import { Button, Layout, Menu, Card, Row, Col } from 'antd';
import './home.css';
import { useAuth0 } from "@auth0/auth0-react";
const { Header, Content, Footer } = Layout;

const items = [
  {
    key: '1',
    label: 'Home',
  },
  {
    key: '2',
    label: 'Preços  ',
  }
];

const Home: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [selectedPage, setSelectedPage] = React.useState('1');
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
          onSelect={(item) => {
            setSelectedPage(item.key);
          }}
        />
        <Button
          onClick={() => loginWithRedirect()}
        >
          Entrar na Plataforma
        </Button>
      </Header>
      {
        selectedPage === '1' ? (
          <Content>
            <div className="section">
              <h1>Seja bem-vindo ao nosso portal dedicado a <br />simplificar sua jornada de financiamento com a  <br />Caixa Econômica Federal</h1>
            </div>

            <div className="section bg1">
              <h1>Aqui, você encontrará uma lista abrangente de documentos <br /> essenciais para garantir um processo eficiente e tranquilo.</h1>
            </div>

            <div className="section bg2">
              <h1>Nosso objetivo é fornecer a você todas as informações necessárias <br />
                para que você possa acessar os recursos financeiros  <br />que precisa, com clareza e praticidade.</h1>
            </div>

            <div className="section bg3">
              <h1>Prepare-se para dar o primeiro passo rumo  <br />aos seus objetivos financeiros com confiança e segurança.</h1>
            </div>

            <div className="section bg4">
              <h1>Explore nossos recursos e comece sua jornada hoje mesmo!</h1>
            </div>

          </Content>
        ) : (
          <Content style={
            {
              padding: '0 48px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }
          }>
            <Row justify={'space-around'} gutter={16}>
              <Col span={8}>
                <Card title="Plano Básico" style={{ width: 300 }}>
                  <p>Plano de assinatura básico</p>
                  <p>R$ 10,00</p>
                  <Button type="primary">Comprar</Button>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Plano Intermediário" style={{ width: 300 }}>
                  <p>Plano de assinatura intermediário</p>
                  <p>R$ 20,00</p>
                  <Button type="primary">Comprar</Button>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Plano Premium" style={{ width: 300 }}>
                  <p>Plano de assinatura premium</p>
                  <p>R$ 30,00</p>
                  <Button type="primary">Comprar</Button>
                </Card>
              </Col>
            </Row>
          </Content>
        )
      }
      <Footer style={{ textAlign: 'center' }}>
        Conformidade App {new Date().getFullYear()}. Todos os direitos reservados.
      </Footer>
    </Layout>
  );
};

export default Home;