import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routes from "./routes";
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain="prod-schmidtdev.us.auth0.com"
    clientId="pj4KeRELZjsxCiw1cVqpCZH0Ma1jE4pb"
    authorizationParams={{
      redirect_uri: window.location.origin+"/dashboard",
      audience: "https://prod-schmidtdev.us.auth0.com/api/v2/",
      scope: "read:current_user email"
    }}
    
  >
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </Auth0Provider>
);

