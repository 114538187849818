import React, { useState } from 'react';
import {
    HomeFilled,
    HomeOutlined,
    SunFilled,
    MoonFilled,
    InfoCircleOutlined,
    AlertFilled,
} from '@ant-design/icons';
import type { MenuProps, CheckboxProps } from 'antd';
import { Col, Row, Layout, Menu, theme, Image, Flex, Divider, Checkbox, List, Button, Space, Drawer, Card, notification, Result, Avatar, Popover } from 'antd';

import { useAuth0 } from '@auth0/auth0-react';

import Logo from '../../assets/img/caixa.png';
import dataJson from '../../data/data.json';

import RG from '../../assets/img/rg.jpg';

const docs: Record<string, string> = {
    "RG": RG
}

const { Content, Footer, Sider, Header } = Layout;

export interface CkeckData {
    id: number;
    label: string;
    image: string[];
}

export interface Items {
    items: CkeckData[];
    obs: string;
}


type MenuItem = Required<MenuProps>['items'][number];

interface ThemeProvider {
    handleTheme: () => void;
    isDarkMode: boolean;
}

const CheckPage: React.FC<ThemeProvider> = (
    { handleTheme, isDarkMode },
) => {
    const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    // async function getToken() {
    //     try {
    //         const token = await getAccessTokenSilently(
    //             {
    //                 authorizationParams: {
    //                     audience: 'https://prod-schmidtdev.us.auth0.com/api/v2/',
    //                 },
    //             })

    //         const response = await fetch(`https://prod-schmidtdev.us.auth0.com/api/v2/users/${user?.sub}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         })
    //         const user_metadata = await response.json();
    //         console.log(user_metadata);
            
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    //getToken();


    // Data
    const [selected, setSelected] = useState<number[]>([]);
    const [imovelName, setImovelName] = useState('Imovel Novo');

    // Theme
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    // Sidebar
    const [collapsed, setCollapsed] = useState(false);

    const onChange: CheckboxProps['onChange'] = (e) => {
        const id = parseInt(e.target.id as string);
        const index = selected.indexOf(id);
        if (index === -1) {
            selected.push(id);
        } else {
            selected.splice(index, 1);
        }
        setSelected([...selected]);
    };

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            onClick: () => {
                if (children === undefined)
                    setImovelName(label as string);
            }
        } as MenuItem;
    }

    function getImovel(): Record<string, Items> {
        if (imovelName === 'Imovel Novo') {
            return dataJson["IMOVEL_NOVO"] as Record<string, Items>;
        } else if (imovelName === 'Imovel Usado') {
            return dataJson["IMOVEL_USADO"] as Record<string, Items>;
        } else if (imovelName === 'Pró-Cotista Novo') {
            return dataJson["PRO_COTISTA_NOVO"] as Record<string, Items>;
        } else if (imovelName === 'Pró-Cotista Usado') {
            return dataJson["PRO_COTISTA_USADO"] as Record<string, Items>;
        } else if (imovelName === 'SBPE Novo') {
            return dataJson["SBPE_NOVO"] as Record<string, Items>;
        } else if (imovelName === 'SBPE Usado') {
            return dataJson["SBPE_USADO"] as Record<string, Items>;
        } else if (imovelName === 'Real Fácil') {
            return dataJson["REDE_FACIL"] as Record<string, Items>;
        }
        return {} as Record<string, Items>;
    }

    const items: MenuItem[] = [
        getItem('Minha Casa Minha Vida', 'sub1', <HomeOutlined />, [
            getItem('Imovel Novo', '1', <HomeFilled />),
            getItem('Imovel Usado', '2', <HomeFilled />),
        ]),
        getItem('Pró-Cotista', 'sub2', <HomeOutlined />, [
            getItem('Pró-Cotista Novo', '3', <HomeFilled />),
            getItem('Pró-Cotista Usado', '4', <HomeFilled />),
        ]),
        getItem('SBPE', 'sub3', <HomeOutlined />, [
            getItem('SBPE Novo', '5', <HomeFilled />),
            getItem('SBPE Usado', '6', <HomeFilled />),
        ]),
        getItem('Real Fácil', '7', <HomeOutlined />),
    ];

    // Drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const [imageDrawer, setImageDrawer] = useState('');
    const [drawerInfo, setDrawerInfo] = useState<CkeckData>({} as CkeckData);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    // Notification
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (text: string) => {
        api.warning({
            message: 'Observação',
            description: text,
            duration: 0,
        });
    };

    const [openUserAvatar, setOpenUserAvatar] = useState(false);

    const handleOpenChangeUserAvatar = (newOpen: boolean) => {
        setOpenUserAvatar(newOpen);
    };

    if (!isAuthenticated) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Desculpe, você não tem acesso a esta página."
                extra={<Button href='/'>Voltar</Button>}
                style={
                    {
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: isDarkMode ? '#001529' : '#fff'
                    }
                }
            />
        );
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                breakpoint="lg"
                width={250}
            >
                <Divider />
                <Flex gap="middle" align="center" vertical>
                    <div className="logo">
                        <Image src={Logo} alt="Logo" width={80} preview={false} />
                    </div>
                </Flex>
                <Divider />
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
            </Sider>
            <Layout>
                <Content style={{
                    marginLeft: 20,
                    overflow: 'auto',
                    borderRadius: borderRadiusLG,
                    height: '90vh',
                }}>
                    <Divider />
                    <Header style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}>
                        <Space size="middle" align='start' style={{ float: 'left' }} direction='horizontal'>
                            {imovelName}
                        </Space>
                        <Space size="middle" align='end' style={{ float: 'right' }} direction='horizontal'>
                            <Popover
                                content={
                                    <Space direction="horizontal"
                                    >
                                        <Button onClick={handleTheme}>
                                            {isDarkMode ? <SunFilled /> : <MoonFilled />}
                                        </Button>
                                        <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                            Sair
                                        </Button>
                                    </Space>
                                }
                                title={user?.email}
                                trigger="click"
                                open={openUserAvatar}
                                onOpenChange={handleOpenChangeUserAvatar}
                            >
                                <Avatar size="large" style={
                                    {
                                        cursor: 'pointer',
                                        backgroundColor: isDarkMode ? 'grey' : '#1890ff',
                                    }
                                }> GA</Avatar>
                            </Popover>
                        </Space>
                    </Header>
                    <Divider />
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {
                            Object.keys(getImovel()).map((key: string) => {
                                return (
                                    <Row key={key}>
                                        <List
                                            header={
                                                getImovel()[key].obs !== "" ? <Row justify="space-between">{key}<AlertFilled
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={
                                                        () => {
                                                            openNotification(getImovel()[key].obs);
                                                        }
                                                    }
                                                /></Row> : <div>{key}</div>
                                            }
                                            itemLayout="horizontal"
                                            dataSource={getImovel()[key].items}
                                            bordered={true}
                                            renderItem={item => (
                                                <List.Item key={item.id}>
                                                    <Col span={24}>
                                                        <Checkbox
                                                            onChange={onChange}
                                                            id={item.id.toString()}
                                                            checked={selected.includes(item.id)}
                                                            style={{ textDecoration: selected.includes(item.id) ? 'line-through' : 'none' }}
                                                        >{item.label}</Checkbox>
                                                        {
                                                            item.image.length > 0 ? <InfoCircleOutlined
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={
                                                                    () => {
                                                                        setImageDrawer(docs[item.image[0]]);
                                                                        setDrawerInfo(item);
                                                                        showDrawer();
                                                                    }
                                                                }
                                                            /> : null
                                                        }
                                                    </Col>
                                                </List.Item>
                                            )}
                                        />

                                    </Row>
                                );
                            })
                        }
                    </Space>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Conformidade App {new Date().getFullYear()}. Todos os direitos reservados.
                </Footer>
            </Layout>
            <Drawer title="Informação" onClose={closeDrawer} open={openDrawer}>
                <Divider />
                <Card bordered={false}
                    style={{ width: 300 }}
                    cover={<img alt="drawerImg" src={imageDrawer} />}
                >
                    <p>{drawerInfo.label}</p>
                </Card>
            </Drawer>
            {contextHolder}
        </Layout>
    );
};

export default CheckPage;