import { Route, Routes, BrowserRouter } from "react-router-dom";

import CheckPage from './pages/checkPage/checkPage';
import Home from './pages/home/home';

import { useState } from "react";
import { ConfigProvider, theme,Spin } from "antd";

import { useAuth0 } from "@auth0/auth0-react";

const MainRoutes = () => {
    const { defaultAlgorithm, darkAlgorithm } = theme;
    
    if (localStorage.getItem('darkMode') === null) {
        localStorage.setItem('darkMode', 'true');
    }

    function setDarkMode(value: boolean) {
        localStorage.setItem('darkMode', value.toString());
        setIsDarkMode(value);
    }

    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const { isLoading } = useAuth0();
    if (isLoading) return (
        <div>
            <Spin
            style={
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: isDarkMode ? '#001529' : '#fff'
                }
            }
            />
        </div>
    )
    return (
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    algorithm: localStorage.getItem('darkMode') === 'true' ? darkAlgorithm : defaultAlgorithm,
                }}>

                <Routes>
                    <Route element={<Home />} path='/' />
                    <Route element={<CheckPage
                        handleTheme={() => setDarkMode(!isDarkMode)}
                        isDarkMode={isDarkMode}
                    />} path='/dashboard' />
                </Routes>
            </ConfigProvider>
        </BrowserRouter>
    )
}

export default MainRoutes;